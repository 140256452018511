/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* Alternative Orange Color: #eb6600 */

.screen {
  width: 100%;
  height: 100vh;
  background: url('./assets/gtc-background.png') no-repeat fixed;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.screen .logo {
  padding: 2rem 4rem;
  display: flex;
  justify-content: flex-end;
}

.screen .logo img {
  width: 80px;
}

.screen .container {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.container .menu {
  display: flex;
  margin-bottom: 16px;
}

.menu .menu-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: 16px;
}

.menu .menu-item .menu-account {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.menu .menu-item.active .menu-account {
  background: #e14900;
  color: #FFFFFF;
}

.menu .menu-item.active .menu-text {
  color: #e14900;
}

.container .content-area {
  position: relative;
}

.container .content {
  flex: 1;
  background: #FFFFFF;
  box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
}

.content .content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
  width: 80%;
}

.content .content-top .gtc-logo {
  position: relative;
  width: 200px;
}

.content .content-top .gtc-logo small {
  color: #e14900;
  position: absolute;
  left: 12px;
  top: -7.5px;
}  

.content .content-top .gtc-logo img {
  width: 180px;
}

.content .content-top .progress-bar {
  width: 150px;
  background: #f2f2f2;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
}

.content .content-top .progress-bar .bar {
  height: 5px;
  width: 100%;
  border-radius: 5px;
  background: #bbbbbb;
  margin-top: 2.5px;
}

.progress-bar .bar span {
  background: #e14900;
  height: 5px;
  border-radius: 5px;
  display: block;
}

.messages {
  padding: 1rem 3rem;
  padding-top: 0rem;
  width: fit-content;
}

.messages .alert {
  background: #106cc8;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  color: #ffffff;
  font-size: 0.85rem;
  border-radius: 2.5px;
}

.required-msg {
  font-size: 0.85rem;
  color: #e14900;
}

.content form .inputs {
  padding: 1rem 3rem;
}

.inputs .input-double,
.inputs .input-single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 80%;
}

.inputs .input-double .input-item {
  width: 45%;
  position: relative;
}

.inputs .input-single .input-item {
  width: 100%;
  position: relative;
}

.inputs .input-item label,
.document .input-item label p {
  color: #b0b0b0;
  display: block;
  font-size: 13px;
}

.inputs .input-item label span.required {
  color: #e14900;
  font-size: 1.2rem;
}

.inputs .input-item input {
  border: none;
  outline: none;
  border-radius: 0px;
  border-bottom: 2px solid #b0b0b0;
  width: 100%;
  font-size: 1.2rem;
  padding: 5px 0px;
  color: #707070;
  font-size: 16px;
  padding-left: 2.5px;
}

.inputs .input-item input::placeholder {
  opacity: 0.25;
}

.inputs .input-item input:focus {
  border-bottom: 2px solid #e14900;
}

.inputs .input-item input[type='file'] {
  display: none;
}

.document .input-item label {
  width: 180px;
  text-align: center;
  cursor: pointer;
}

.document .input-item label.active,
.document .input-item label.active p {
  color: #e14900;
}

.inputs .input-item .drop-down {
  width: 100%;
  max-height: 180px;
  position: absolute;
  z-index: 999999;
  overflow-y: scroll;
  background: #ffffff;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  box-shadow: 0 0 6px rgba(149, 149, 149, 0.5);
}

.inputs .input-item .drop-down p {
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

.inputs .input-item .drop-down p:hover {
  background: #e6e6e6;
}

.section-band {
  padding: 1rem 3rem;
  background: #e8e8e8;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.section-band .band {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-band.active {
  background: #e14900;
}

.section-band.active p,
.section-band.active i {
  color: #ffffff;
}

.submit-button {
  padding: 1rem 3rem;
  display: flex;
}

.submit-button button {
  padding: 16px 32px;
  background: #e14900;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  margin-right: 1rem;
}

.submit-button button:hover {
  transform: scale(0.95);
  cursor: pointer;
}

.submit-button button:disabled {
  background: #d7d7d7;
  color: #bbbbbb;
  cursor: no-drop;
}

.submit-button .creating {
  display: flex;
  align-items: center;
}

.submit-button .creating svg {
  margin-right: 0.5rem;
}

.submit-button .creating p {
  font-size: 0.75rem;
  font-style: italic;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

.overlay p {
  color: #434a51;
}

.overlay .overlay-content {
  width: 40%;
  background: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 2rem;
  z-index: 999999999;
  text-align: center;
}

.overlay .overlay-content .alert-type {
  background: seagreen;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay .overlay-content > img {
  width: 60px;
  height: 60px;
}

.text-area .top-text-area {
  font-weight: 100;
  margin-bottom: 1rem;
  font-size: 0.85rem;
}

.text-area h2.account {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 3rem;
  color: #434a51;
}

.text-area h2.account-red {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 3rem;
  color: #ff0000;
}

.text-area .actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-area .actions p {
  font-size: 0.85rem;
  color: #e14900;
  margin-right: 2rem;
  text-decoration: underline;
  cursor: pointer;
}

.text-area .actions button {
  padding: 16px 32px;
  background: #e14900;
  border-radius: 5px;
  color: #ffffff;
  border: none;
}

.text-area .actions button:hover {
  transform: scale(0.95);
  cursor: pointer;
}





.container .graphics {
  position: absolute;
  top: 80px;
  right: -30px;
  z-index: 99999;
  transform: scale(1.2);
}

@media (max-width: 1080px) {
  .container .graphics {
      display: none;
  }

  .inputs .input-double,
  .inputs .input-single,
  .section-band .band {
      width: 100%;
  }

  .content .content-top {
      width: 100%;
  }
}

@media (max-width: 480px) {
  .screen .logo {
      padding: 2rem;
  }
  
  .screen .container {
      width: 90%;
  }

  .content .content-top {
      width: 100%;
      justify-content: center;
      align-items: center;
  }

  .menu .menu-item .menu-account {
      background: #bbbbbb;
  }

  .menu .menu-item .menu-text {
      display: none;
  }

  .menu .menu-item.active .menu-text {
      display: block;
  }

  .container .graphics,
  .content .content-top .progress-bar {
      display: none;
  }

  .messages {
      padding: 1rem;
      padding-top: 0rem;
  }

  .messages .alert {
      background: #106cc8;
      padding: 0.5rem 1rem;
      margin-top: 1rem;
      color: #ffffff;
      font-size: 0.85rem;
      border-radius: 2.5px;
  }

  .content form .inputs {
      padding: 1rem;
  }

  .inputs .input-double {
      flex-direction: column;
      width: 100%;
  }

  .inputs .input-double .input-item:first-child {
      margin-bottom: 32px;
  }

  .inputs .input-double .input-item,
  .inputs .input-single,
  .inputs .input-single .input-item,
  .section-band .band,
  .inputs .input-double .input-item label {
      width: 100%;
  }

  .section-band {
      padding: 1rem;
  }

  .submit-button {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
  }

  .submit-button .creating {
      justify-content: center;
      margin-bottom: 1rem;
  }

  .submit-button button {
      margin-right: 0rem;
      margin-bottom: 1rem;
  }

  .overlay .overlay-content {
      width: 75%;
      padding: 2rem;
  }

  .text-area .actions {
      flex-direction: column;
  }

  .text-area .actions p {
      margin-right: 0rem;
      margin-bottom: 1rem;
  }
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #e14900 !important;
}
