body {
  /* 
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: 'SF-Pro';
  src: url('./assets/SF-Pro-Text-Regular.otf') format('opentype'),
      url('./assets/SF-Pro-Display-Regular.otf') format('opentype')
} */

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li {
  margin: 0;
  /* font-family: 'SF-Pro', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  font-weight: 400;
  color: #707070;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
